import React, { useState, useEffect } from "react";

import './ReturnResponse.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/InterfacePopUp";
import { SvgChekedReturn } from "components/SvgFile";

export default function PopUP_ReturnResponse(){

    const [ modalData, setModaldata ]     = useState({});
    const [ showPopUp, setShowPopUp ]     = useState(false);

    useEffect(()=>{
        RegisterModalData('ReturnResponse', setModaldata);
        RegisterModalObserver('ReturnResponse', setShowPopUp);
    }, []);

    function ClosePopUp(){
        SetModalState('ReturnResponse', false);
    }

    return(
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all ReturnResponse">
                        <div className="div_data">
                            <div className="content">
                                <SvgChekedReturn color="#43a047" className="icons_return" />
                                <div className="title_return">
                                    Informações registradas com sucesso.
                                </div>
                                <div className="close_return" onClick={ ()=>{ ClosePopUp() } }>
                                    Fechar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    )
};
