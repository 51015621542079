import { useState, useEffect, useRef } from "react";

import './Details.css';

import Select from 'react-select';

import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

import { SvgDelete, SvgImg, SvgReturn } from "components/SvgFile";

import { Reg_Panels } from "services/RegisterData";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";

export default function Panels_Details(){

    const editText = useRef();
    const [ loading, setLoading ] = useState(false);

    const [ dataPage, setDataPage ] = useState(GetDataPage('panels'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ idData, setIdData ]                 = useState(InitialData('idData'))
    const [ type, setType ]                     = useState(InitialData('type'));
    const [ format, setFormat ]                 = useState(InitialData('format'));
    const [ branch, setBranch ]                 = useState(InitialData('branch'));
    const [ newBranch, setNewBranch ]           = useState('');
    const [ premium, setPremium ]               = useState(InitialData('premium'));
    const [ available, setAvailable ]           = useState(InitialData('available'));
    const [ busyUntil, setBusyUntil ]           = useState(InitialData('busy_until'));
    const [ state, setState ]                   = useState(InitialData('state'));
    const [ city, setCity ]                     = useState(InitialData('city'));
    const [ neighborhood, setNeighborhood ]     = useState(InitialData('neighborhood'));
    const [ coverage, setCoverage ]             = useState(InitialData('coverage'));
    const [ address, setAddress ]               = useState(InitialData('address'));
    const [ latitude, setLatitude ]             = useState(InitialData('latitude'));
    const [ longitude, setLongitude ]           = useState(InitialData('longitude'));
    const [ streetView, setStreetView ]         = useState(InitialData('street_view'));
    const [ flowGenerators, setFlowGenerators ] = useState(InitialData('flow_generators'));
    const [ codeVideo, setCodeVideo ]           = useState(InitialData('code_video'));
    const [ size, setSize ]                     = useState(InitialData('size'));
    const [ impactsMonth, setImpactsMonth ]     = useState(InitialData('impacts_month'));
    const [ publicType, setPublicType ]         = useState(InitialData('public'));
    const [ similar_1, setSimilar_1 ]           = useState(InitialData('similar_1'));
    const [ similar_2, setSimilar_2 ]           = useState(InitialData('similar_2'));
    const [ similar_3, setSimilar_3 ]           = useState(InitialData('similar_3'));

    const [ file, setFile ]           = useState(InitialData('file'));
    const [ stateFile, setStateFile ] = useState(false);

    const [ newHood, setNewHood ]     = useState('');
    const [ newCvrg, setNewCvrg ]     = useState([]);

    const [ listType, setListType ] = useState([
        { "value": "topsight", "label": "Top-Sight/Front-Light" },
        { "value": "outdoor", "label": "Outdoor" },
        { "value": "painelrodoviario", "label": "Painel Rodoviário" },
        { "value": "paineldigital", "label": "Painel Digital" },
        { "value": "painelled", "label": "Painel Led" }
    ]);
    const [ listFormat, setListFormat ] = useState([
        { "value": "topsight", "label": "Top-Sight" },
        { "value": "frontlight", "label": "Front-Light" },
        { "value": "10x5", "label": "10x5" },
        { "value": "12x5", "label": "12x5" },
        { "value": "15x5", "label": "15x5" },
        { "value": "20x5", "label": "20x5" }
    ]);
    const [ listPremium, setListPremium ] = useState([
        { "value": "false", "label": "Não" },
        { "value": "true", "label": "Sim" }
    ]);
    const [ listAvailable, setListAvailable ] = useState([
        { "value": "false", "label": "Não" },
        { "value": "true", "label": "Sim" }
    ]);

    useEffect(()=>{
        RegisterDataPage("panels", setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('panels'));
        setIdPage(GetListPag('currentPageId'));

        setIdData(InitialData('idData'));
        setType(InitialData('type'));
        setFormat(InitialData('format'));
        setBranch(InitialData('branch'));
        setPremium(InitialData('premium'));
        setAvailable(InitialData('available'));
        setBusyUntil(InitialData('busy_until'));
        setState(InitialData('state'));
        setCity(InitialData('city'));
        setNeighborhood(InitialData('neighborhood'));
        setCoverage(InitialData('coverage'));
        setAddress(InitialData('address'));
        setLatitude(InitialData('latitude'));
        setLongitude(InitialData('longitude'));
        setStreetView(InitialData('street_view'));
        setFlowGenerators(InitialData('flow_generators'));
        setCodeVideo(InitialData('code_video'));
        setSize(InitialData('size'));
        setImpactsMonth(InitialData('impacts_month'));
        setPublicType(InitialData('public'));
        setSimilar_1(InitialData('similar_1'));
        setSimilar_2(InitialData('similar_2'));
        setSimilar_3(InitialData('similar_3'));
        setFile(InitialData('file'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('panels'));
        setIdPage(GetListPag('currentPageId'));

        setIdData(InitialData('idData'));
        setType(InitialData('type'));
        setFormat(InitialData('format'));
        setBranch(InitialData('branch'));
        setPremium(InitialData('premium'));
        setAvailable(InitialData('available'));
        setBusyUntil(InitialData('busy_until'));
        setState(InitialData('state'));
        setCity(InitialData('city'));
        setNeighborhood(InitialData('neighborhood'));
        setCoverage(InitialData('coverage'));
        setAddress(InitialData('address'));
        setLatitude(InitialData('latitude'));
        setLongitude(InitialData('longitude'));
        setStreetView(InitialData('street_view'));
        setFlowGenerators(InitialData('flow_generators'));
        setCodeVideo(InitialData('code_video'));
        setSize(InitialData('size'));
        setImpactsMonth(InitialData('impacts_month'));
        setPublicType(InitialData('public'));
        setSimilar_1(InitialData('similar_1'));
        setSimilar_2(InitialData('similar_2'));
        setSimilar_3(InitialData('similar_3'));
        setFile(InitialData('file'));
    }, [idPage]);

    function InitialData(type){
        if(idPage != 0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'state'){
                let nameState = newData['sigla'] + ' - ' + newData['state'];
                if(newData['state'] !=''){
                    return { "value": nameState, "label": nameState };
                }
                return '';
            }

            if(type == 'premium' || type == 'available'){
                if(newData[type] == 'true'){
                    return { "value": newData[type], "label": 'Sim' };
                }else if(newData[type] == 'false'){
                    return { "value": newData[type], "label": 'Não' };
                }
                return '';
            }

            if(type == 'type' || type == 'format' || type == 'branch' || type == 'city' || type == 'neighborhood'){
                if(newData[type] !=''){
                    return { "value": newData[type], "label": newData[type] };
                }
                return '';
            }
            return newData[type];
        }
        if(type == 'coverage'){
            return [];
        }
        return '';
    }

    function ListBranch(){
        const options = [{ "value": "add", "label": "Adicionar nova filial" }];
        const newData = [];
        dataPage.map((key, index)=>{
            if(key.branch !=''){
                newData.push(key.branch);
            }
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        duplicate.map((key, index)=>{
            options.push({
                "value": key, "label": key
            });
        })
        return options;
    }

    function ShowState(){
        const options = []
        GetDataPage('state').map((key, index)=>{
            let nameState = key.sigla + ' - ' + key.nome;
            options.push({
                "value": nameState, "label": nameState
            });
        });
        return options;
    }

    function ShowCity(){
        const options = []
        if(state !=''){
            let sep = state['value'].split('-');
            if(sep[1].replace(' ', '') !=''){
                const newData = GetDataPage('state').find(item => item.nome == sep[1].replace(' ', ''));
                if(newData){
                    newData['cidades'].map((key, index)=>{
                        options.push({
                            "value": key.replace('&#39;', "'"), "label": key.replace('&#39;', "'")
                        });
                    })
                }
            }
        }
        return options;
    }

    function ShowNeighborhood(){
        const options = [{ "value": "add", "label": "Adicionar novo bairro" }];
        const newData = [];
        dataPage.map((key, index)=>{
            if(key.neighborhood !=''){
                newData.push(key.neighborhood);
            }
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        duplicate.map((key, index)=>{
            options.push({
                "value": key, "label": key
            });
        })
        return options;
    }

    function ShowCoverage(){
        const options = []
        dataPage.map((key, index)=>{
            if(key.neighborhood !=''){
                options.push({
                    "value": key.neighborhood, "label": key.neighborhood
                });
            }
        })
        const duplicate = options.filter((item, index) => options.indexOf(item) === index);
        return duplicate;
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function AddFile(value){
        if(value){
            setStateFile(true);
            setFile(value);
        }else {
            setStateFile(false);
            setFile('');
        }
    }

    function Delete(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "panels", "type": "delete_coverage", "name": value });
        SetModalState('Confirmation', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        const show_branch    = branch.value;
        const show_newBranch = newBranch;

        Reg_Panels(idPage, idData, type !='' ? type.value : '', file, format !='' ? format.value : '', premium !='' ? premium.value : '', available !='' ? available.value : '', busyUntil, state !='' ? state.value : '', city !='' ? city.value : '', neighborhood !='' ? neighborhood.value : '', coverage, address, latitude, longitude, streetView, flowGenerators, codeVideo, size, impactsMonth, publicType, similar_1, similar_2, similar_3, newHood, newCvrg, show_branch, show_newBranch, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        setStateFile(false);
        setNewHood('');
        setNewCvrg('');
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
    }

    function ReturnPage(){
        SetListPag('currentPage', 'panels');
        SetListPag('currentPageId', 'remuve');
    }

    return(
        <div className="Panels_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#000000" className="icons" />
                        Voltar
                    </div>
                    <div className="name_title_page">
                        Painel
                    </div>
                    <div className="list_opt_alt_page">
                        <button className="new_block_text new_register">
                            Salvar
                        </button>
                    </div>
                </div>
                <div className="show_data_register">

                    <div className="div_data_project">
                        <div className="div_input">
                            <input type="text" className="id_data" onChange={ (e)=>{ setIdData(e.target.value) } } value={ idData } maxLength="10" required />
                            <span className="span_name_input">ID</span>
                        </div>

                        <div className={ stateFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { stateFile == true ? "Imagem adicionada" : "Adicionar imagem" }
                                {
                                    file !='' ?
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" /> :
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" required />
                                }
                            </label>
                        </div>
                        {
                            file !='' ?
                                stateFile == true ?
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div> :
                                <div className="show_img_" onClick={ ()=>{ OpenFile('folderFixed', file) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div>
                            : null
                        }
                    </div>

                    <div className="div_data_project">
                        <div className="div_input">
                            <label>Tipo</label>
                            <Select className="select_type" defaultOptions options={ listType } value={ type } onChange={ (e)=> { setType(e); } } placeholder="..." required />
                        </div>
                        <div className="div_input">
                            <label>Formato</label>
                            <Select className="select_format" defaultOptions options={ listFormat } value={ format } onChange={ (e)=> { setFormat(e); } } placeholder="..." />
                        </div>
                        <div className="div_input space_data">
                            <label>Filial</label>
                            <Select className="select_branch" defaultOptions options={ ListBranch() } value={ branch } onChange={ (e)=> { setBranch(e); } } placeholder="..." required />
                        </div>
                        {
                            branch.value == 'add' ?
                            <div className="div_input">
                                <label className="name_filial">Nome da filial</label>
                                <input type="text" className="name_filial_input" onChange={ (e)=>{ setNewBranch(e.target.value) } } value={ newBranch } required />
                            </div>
                            : null
                        }
                    </div>

                    <div className="div_data_project">
                        <div className="div_input">
                            <label>Premium</label>
                            <Select className="select_premium" defaultOptions options={ listPremium } value={ premium } onChange={ (e)=> { setPremium(e); } } placeholder="..." />
                        </div>
                        <div className="div_input">
                            <label>Disponível</label>
                            <Select className="select_premium" defaultOptions options={ listAvailable } value={ available } onChange={ (e)=> { setAvailable(e); } } placeholder="..." />
                        </div>
                        <div className="div_input">
                            <label>Ocupado até</label>
                            <div>
                                <input type="date" className="" onChange={ (e)=>{ setBusyUntil(e.target.value) } } value={ busyUntil } />
                            </div>
                        </div>
                    </div>

                    <div className="div_data_project">
                        <div className="div_input">
                            <label>Estado</label>
                            <Select className="space_state" defaultOptions options={ ShowState() } value={ state } onChange={ (e)=> { setState(e); setCity(''); setNeighborhood(''); } } placeholder="..." required />
                        </div>
                        <div className="div_input">
                            <label>Cidade</label>
                            <Select className="space_state" defaultOptions options={ ShowCity() } value={ city } onChange={ (e)=> { setCity(e); setNeighborhood(''); } } placeholder="..." required />
                        </div>
                        <div className="div_input">
                            <label>Bairro</label>
                            <Select className="space_state" defaultOptions options={ ShowNeighborhood() } value={ neighborhood } onChange={ (e)=> { setNeighborhood(e); } } placeholder="..." required />
                        </div>
                    </div>

                    {
                        neighborhood.value == 'add' ?
                        <div className="div_data_project">
                            <div className="div_input space_data">
                                <input type="text" className="" onChange={ (e)=>{ setNewHood(e.target.value) } } required />
                                <span className="span_name_input">Nome do bairro</span>
                            </div>
                        </div>
                        : null
                    }

                    <div className="div_show_text">
                        <div className="show_title">
                            <div className="title_div">Cobertura</div>
                        </div>
                        <div className="show_textarea">
                            <Select className="space_data" defaultOptions options={ ShowCoverage() } onChange={ (e)=> { setNewCvrg(e); } } value={ newCvrg } placeholder="..." isMulti isOptionDisabled={ (opt)=> coverage.length >= 3} />
                        </div>
                        {
                            coverage.map((key, index)=>{
                                return(
                                    <div className="show_coverage" key={ index }>
                                        <div className="">
                                            Bairro: { key.name }
                                        </div>
                                        <div className="" onClick={ ()=>{ Delete(key.id, key.name) } } style={ { display: 'flex' } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setAddress(e.target.value) } } value={ address } required />
                            <span className="span_name_input">Endereço</span>
                        </div>
                    </div>

                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setLatitude(e.target.value) } } value={ latitude } required />
                            <span className="span_name_input">Latitude</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setLongitude(e.target.value) } } value={ longitude } required />
                            <span className="span_name_input">Longitude</span>
                        </div>
                    </div>

                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setStreetView(e.target.value) } } value={ streetView } />
                            <span className="span_name_input">Vista da rua</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setFlowGenerators(e.target.value) } } value={ flowGenerators } />
                            <span className="span_name_input">Geradores de fluxo</span>
                        </div>
                    </div>

                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setCodeVideo(e.target.value) } } value={ codeVideo } />
                            <span className="span_name_input">Código do Youtube</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="" onChange={ (e)=>{ setSize(e.target.value) } } value={ size } />
                            <span className="span_name_input">Tamanho</span>
                        </div>
                    </div>

                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setImpactsMonth(e.target.value) } } value={ impactsMonth } />
                            <span className="span_name_input">Impactos/mês</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setPublicType(e.target.value) } } value={ publicType } />
                            <span className="span_name_input">Público</span>
                        </div>
                    </div>

                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setSimilar_1(e.target.value) } } value={ similar_1 } />
                            <span className="span_name_input">Similar 1</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setSimilar_2(e.target.value) } } value={ similar_2 } />
                            <span className="span_name_input">Similar 2</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setSimilar_3(e.target.value) } } value={ similar_3 } />
                            <span className="span_name_input">Similar 3</span>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}
