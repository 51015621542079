import { useState, useEffect, useRef } from "react";

import './Home.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

import { Reg_Home } from "services/RegisterData";

import { SvgReturn } from "components/SvgFile";

export default function Home(){

    const editText = useRef();
    const [ loading, setLoading ] = useState(false);

    const [ dataPage, setDataPage ] = useState(GetDataPage('home'));

    useEffect(()=>{
        RegisterDataPage("home", setDataPage);
    }, []);

    useEffect(()=>{
    }, [dataPage]);

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Home(()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
    }

    return(
        <div className="Home">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="name_title_page">
                        Home
                    </div>
                    <div className="list_opt_alt_page">
                        <button className="new_block_text new_register">
                            Salvar
                        </button>
                    </div>
                </div>
                <div className="show_data_register">

                </div>
            </form>
        </div>
    )
}
