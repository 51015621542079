import { useState, useEffect } from "react";

import './Contact.css';

import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { Reg_Contact } from "services/RegisterData";
import { SetModalState } from "interface/InterfacePopUp";

export default function Contact(){

    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('contact'));

    const [ subtitle_1, setSubtitle_1 ] = useState(dataPage.subtitle_1)
    const [ phone_1, setPhone_1 ]       = useState(dataPage.phone_1);
    const [ whatsapp_1, setWhatsapp_1 ] = useState(dataPage.whatsapp_1);
    const [ address_1, setAddress_1 ]   = useState(dataPage.address_1);
    const [ email_1, setEmail_1 ]       = useState(dataPage.email_1);

    const [ subtitle_2, setSubtitle_2 ] = useState(dataPage.subtitle_2)
    const [ phone_2, setPhone_2 ]       = useState(dataPage.phone_2);
    const [ whatsapp_2, setWhatsapp_2 ] = useState(dataPage.whatsapp_2);
    const [ address_2, setAddress_2 ]   = useState(dataPage.address_2);
    const [ email_2, setEmail_2 ]       = useState(dataPage.email_2);

    const [ subtitle_3, setSubtitle_3 ] = useState(dataPage.subtitle_3)
    const [ phone_3, setPhone_3 ]       = useState(dataPage.phone_3);
    const [ whatsapp_3, setWhatsapp_3 ] = useState(dataPage.whatsapp_3);
    const [ address_3, setAddress_3 ]   = useState(dataPage.address_3);
    const [ email_3, setEmail_3 ]       = useState(dataPage.email_3);

    useEffect(()=>{
        RegisterDataPage("contact", setDataPage);
    }, []);

    useEffect(()=>{
        setSubtitle_1(dataPage.subtitle_1);
        setPhone_1(dataPage.phone_1);
        setWhatsapp_1(dataPage.whatsapp_1);
        setAddress_1(dataPage.address_1);
        setEmail_1(dataPage.email_1);

        setSubtitle_2(dataPage.subtitle_2);
        setPhone_2(dataPage.phone_2);
        setWhatsapp_2(dataPage.whatsapp_2);
        setAddress_2(dataPage.address_2);
        setEmail_2(dataPage.email_2);

        setSubtitle_3(dataPage.subtitle_3);
        setPhone_3(dataPage.phone_3);
        setWhatsapp_3(dataPage.whatsapp_3);
        setAddress_3(dataPage.address_3);
        setEmail_3(dataPage.email_3);
    }, [dataPage]);

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Contact(
            subtitle_1, phone_1, whatsapp_1, address_1, email_1,
            subtitle_2, phone_2, whatsapp_2, address_2, email_2,
            subtitle_3, phone_3, whatsapp_3, address_3, email_3, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
    }

    return(
        <div className="Contact">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="name_title_page">
                        Contato
                    </div>
                    <div className="list_opt_alt_page">
                        <button className="new_block_text new_register">
                            Salvar
                        </button>
                    </div>
                </div>

                <div className="show_data_register space_bottom">
                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setSubtitle_1(e.target.value) } } value={ subtitle_1 } maxLength="140" />
                            <span className="span_name_input">Subtítulo</span>
                        </div>
                    </div>
                    <div className="div_data_project ">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setPhone_1(e.target.value) } } value={ phone_1 } maxLength="140" />
                            <span className="span_name_input">Telefone</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setWhatsapp_1(e.target.value) } } value={ whatsapp_1 } maxLength="140" />
                            <span className="span_name_input">Whatsapp</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setEmail_1(e.target.value) } } value={ email_1 } maxLength="140" />
                            <span className="span_name_input">Email</span>
                        </div>
                    </div>
                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setAddress_1(e.target.value) } } value={ address_1 } maxLength="140" />
                            <span className="span_name_input">Endereço</span>
                        </div>
                    </div>
                </div>

                <div className="show_data_register space_bottom">
                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setSubtitle_2(e.target.value) } } value={ subtitle_2 } maxLength="140" />
                            <span className="span_name_input">Subtítulo</span>
                        </div>
                    </div>
                    <div className="div_data_project ">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setPhone_2(e.target.value) } } value={ phone_2 } maxLength="140" />
                            <span className="span_name_input">Telefone</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setWhatsapp_2(e.target.value) } } value={ whatsapp_2 } maxLength="140" />
                            <span className="span_name_input">Whatsapp</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setEmail_2(e.target.value) } } value={ email_2 } maxLength="140" />
                            <span className="span_name_input">Email</span>
                        </div>
                    </div>
                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setAddress_2(e.target.value) } } value={ address_2 } maxLength="140" />
                            <span className="span_name_input">Endereço</span>
                        </div>
                    </div>
                </div>

                <div className="show_data_register space_bottom">
                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setSubtitle_3(e.target.value) } } value={ subtitle_3 } maxLength="140" />
                            <span className="span_name_input">Subtítulo</span>
                        </div>
                    </div>
                    <div className="div_data_project ">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setPhone_3(e.target.value) } } value={ phone_3 } maxLength="140" />
                            <span className="span_name_input">Telefone</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setWhatsapp_3(e.target.value) } } value={ whatsapp_3 } maxLength="140" />
                            <span className="span_name_input">Whatsapp</span>
                        </div>
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setEmail_3(e.target.value) } } value={ email_3 } maxLength="140" />
                            <span className="span_name_input">Email</span>
                        </div>
                    </div>
                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setAddress_3(e.target.value) } } value={ address_3 } maxLength="140" />
                            <span className="span_name_input">Endereço</span>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )
}
