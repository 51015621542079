import { useState, useEffect, useRef } from "react";

import './About.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

import { Reg_About } from "services/RegisterData";

import { SvgReturn } from "components/SvgFile";

export default function About(){

    const editText = useRef();
    const [ loading, setLoading ] = useState(false);

    const [ dataPage, setDataPage ] = useState(GetDataPage('about'));
    const [ title, setTitle ] = useState(dataPage.title);
    const [ text, setText ]   = useState(dataPage.text);

    useEffect(()=>{
        RegisterDataPage("about", setDataPage);
    }, []);

    useEffect(()=>{
        setTitle(dataPage.title);
        setText(dataPage.text);
    }, [dataPage]);

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_About(title, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
    }

    return(
        <div className="About">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="name_title_page">
                        Quem somos
                    </div>
                    <div className="list_opt_alt_page">
                        <button className="new_block_text new_register">
                            Salvar
                        </button>
                    </div>
                </div>
                <div className="show_data_register">

                    <div className="div_data_project">
                        <div className="div_input space_data">
                            <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" />
                            <span className="span_name_input">Título</span>
                        </div>
                    </div>

                    <div className="div_show_text">
                        <div className="show_title">
                            <div className="title_div">Descrição</div>
                        </div>
                        <div className="show_textarea">
                            <JoditEditor ref={ editText } config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}
