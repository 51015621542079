import { useState, useEffect } from "react";

import './Panels.css';

import { SetListPag } from "interface/InterfacePag";
import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

import { SvgDelete, SvgSearch, SvgEdit, SvgAddOpt_2, SvgTriagle } from "components/SvgFile";

export default function Panels(){

    const [ search, setSearch ]     = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('panels'));

    const [ state, setState ]         = useState('All');
    const [ status, setStatus ]       = useState(false);
    const [ listState, setListState ] = useState([]);

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('panels', setShowData);
        ListState();
    }, []);

    function SearchInput(value){
        const newData = [];
        if(value !=''){
            {
                GetDataPage('panels').forEach(item =>{
                    if(item.idData.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.state.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.city.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowData(duplicate);
        }else if(value == ''){
            setShowData(GetDataPage('panels'));
        }
        setSearch(value);
        setStatus(false);
    }

    function ListState(){
        const newData = [];
        GetDataPage('panels').map((key, index)=>{
            newData.push(key.state)
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        setListState(duplicate);
    }

    function SearchState(value){
        const newData = [];
        if(value !=''){
            {
                GetDataPage('panels').forEach(item =>{
                    if(value == 'All'){
                        newData.push(item);
                    }else {
                        if(item.state.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newData.push(item);
                        }
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowData(duplicate);
        }else if(value == ''){
            setShowData(GetDataPage('panels'));
        }
        setState(value);
        setStatus(false);
    }

    function Delete(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "panels", "type": "delete_panels", "name": value });
        SetModalState('Confirmation', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'panels_details')
        SetListPag('currentPageId', idPage);
    }

    return(
        <div className="Panels">
            <div className="new_block">
                <div className="input_search">
                    <div className="input_search">
                        <SvgSearch color="#111827" className="icons_search" />
                        <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required />
                        <span className="span_search_input">Pesquisar...</span>

                        {/*
                        <div className="click_show_search">
                            <div className="div_input" onClick={ ()=>{ setStatus(!status); } }>
                                <div className="div_select search_state">
                                    { state }
                                    {
                                        status == true ?
                                        <SvgTriagle color="#747474" className="icon_select_open" /> :
                                        <SvgTriagle color="#747474" className="icon_select_close" />
                                    }
                                </div>
                                <span className="span_name_select">Estado</span>
                            </div>
                            {
                                status == true ?
                                <div className="click_show_search_selected ">
                                    <div className="opt_list_search" onClick={ ()=>{ SearchState('All'); } }>All</div>
                                    {
                                        listState.map((key, index)=>{
                                            return (
                                                <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchState(key); } }>
                                                    { key }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                : null
                            }
                        </div>
                        */}

                    </div>
                </div>

                <div className="list_opt_alt_page">
                    <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                        <SvgAddOpt_2 color="#ffffff" className="icons" />
                        Painel
                    </div>
                </div>
            </div>
            <div className="">
                <table>
                    <tbody>
                        <tr>
                            <th width="20">#</th>
                            <th width="80">ID</th>
                            <th>Cidade</th>
                            <th>Endereço</th>
                            <th width="20">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td align="left">{ index + 1 }</td>
                                            <td>{ key.idData }</td>
                                            <td>{ key.city }</td>
                                            <td>{ key.address }</td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div onClick={ ()=>{ Delete(key.id, key.title) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div>
                                                    <div onClick={ ()=>{ PageClick(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhum painel encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showData.length >= 30 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}
