import React, { useState, useEffect } from "react";

import './Profile.css';

import { SvgClose, SvgImg } from "components/SvgFile";

import { GetUserData, RegisterUserData } from "interface/InterfaceUsers";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData, GetModalData } from "interface/InterfacePopUp";

import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";
import { Reg_AccessDash } from "services/RegisterData";

export default function PopUP_Profile(){

    const [ modalData, setModaldata ] = useState(GetModalData('Profile'));
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('access_dash'));

    const [ name, setName ]   = useState('');
    const [ email, setEmail ] = useState('');
    const [ file, setFile ]   = useState('');
    const [ pass, setPass ]   = useState('');
    const [ state, setState ] = useState(false);

    useEffect(()=>{
        RegisterModalData('Profile', setModaldata);
        RegisterModalObserver('Profile', setShowPopUp);

        RegisterDataPage('access_dash', setShowDataPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setName(IniditalData('name'));
            setEmail(IniditalData('email'));
            setFile(IniditalData('file'));
        }
    }, [showPopUp]);

    function IniditalData(type){
        if(modalData.id != 0){
            const newData = showDataPage.find(item => item.id == modalData.id);
            return newData[type];
        }
        return '';
    }

    function ClosePopUp(){
        SetModalState('Profile', false);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function saveData(){
        Reg_AccessDash(GetUserData('id'), modalData.id, modalData.type, name, email, file, pass, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        SetModalState('Profile', false);
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setState(true);
        }else {
            setState(false);
        }
    }

    function CallbackSuccess(){
    }

    function CallbackError(){
    }

    return (
        (showPopUp ?
            <>
                <form onSubmit={ saveData }>
                    <div className="PopUp">
                        <div className="all popup_profile">
                            <div className="div_data">
                                <div className="title">
                                    Alterar dados
                                </div>
                                <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                    <SvgClose color="#F00000" className="icons" />
                                </div>
                            </div>
                            <div className="div_data" style={ { paddingTop: 0 } }>
                                <div className="content">
                                    <div className="div_input space_top">
                                        <div className="div_input space_data">
                                            <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="100" required />
                                            <span className="span_name_input">Nome</span>
                                        </div>

                                        <div className="div_input div_pass">
                                            {
                                                modalData.id == 0 ?
                                                <input type="password" className="password" onChange={ (e)=>{ setPass(e.target.value) } }maxLength="100" required /> :
                                                <input type="password" className="password" onChange={ (e)=>{ setPass(e.target.value) } }maxLength="100" />
                                            }
                                            <span className="span_name_input">Senha</span>
                                        </div>
                                    </div>

                                    <div className="div_input space_top">
                                        <div className="div_input space_data">
                                            <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } maxLength="60" required />
                                            <span className="span_name_input">E-mail</span>
                                        </div>

                                        <div className={ state == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { state == true ? "Foto adicionada" : "Adicionar foto" }
                                                <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*"/>
                                            </label>
                                        </div>
                                        {
                                            file !='' ?
                                                state == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div>
                                            : ""
                                        }
                                    </div>
                                    <button type="submit" className="register_data">
                                        Cadastrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
