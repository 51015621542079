
import { SvgClose } from "components/SvgFile";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/InterfacePopUp";
import React, { useState, useEffect } from "react";

import Iframe from 'react-iframe';

import './ShowFile.css';

export default function PopUp_ShowFile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    useEffect(()=>{
        RegisterModalData('ShowFile', setModaldata);
        RegisterModalObserver('ShowFile', setShowPopUp);
    }, []);

    function ClosePopUp(){
        SetModalState('ShowFile', false);
    }

    function OpenFile(){
        switch (modalData.type) {
            case 'folderFixed':
                return <img alt="img" src={ modalData.file } className="show_img" />;

            case 'showImg':
                return <img alt="img" src={ "./assets/" + modalData.file } className="show_img" />;

            case 'showImg_local':
                return <img alt="img" src={ modalData.file } className="show_img" />;

            case 'showPdf':
                return <Iframe url={ "./assets/" + modalData.file } width="98%" height="400px" id="" className="" display="block" position="relative" />

            case 'showPdf_local':
                return <Iframe url={ modalData.file } width="98%" height="400px" id="" className="" display="block" position="relative" />

            case 'Vimeo':
                return <Iframe className="div_video" src={ "https://player.vimeo.com/video/" + modalData.file } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

            case 'Youtube':
                return <Iframe className="div_video" src={ "https://www.youtube.com/embed/" + modalData.file } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

            case 'Drive':
                let linkDrive = modalData.file.split('/');
                return <Iframe className="div_video" src={ "https://drive.google.com/file/d/" + linkDrive[5] + "/preview" }frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all width_popup_file">
                        <div className="div_data">
                            <div className="title">
                                Visualizar arquivo
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                {
                                    OpenFile()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
