import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/InterfacePag';
import { SetListDataSingle } from 'interface/InterfaceData';

import { typeCookie, cookiesRegister, connectionPage, typeCookieEmail, typeCookiePassw } from 'dataFixed';

// Delete
export function Reg_Delete(id, origin, type, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("id", id);
    data.append('type_page', origin);
    data.append('edit_data', type);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        switch (origin) {
            case 'panels':
                    SetListDataSingle('panels', response.data.panels);
                break;
            case 'access_dash':
                    SetListDataSingle('access_dash', response.data.access_dash);
                break;
        }

        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Access Dash
export function Reg_AccessDash(idUser, id, type, name, email, file, pass, CallbackSuccess, CallbackError){

    const cookies   = new Cookies();
    const reg_email = cookies.get(typeCookieEmail);
    const reg_passw = cookies.get(typeCookiePassw);

    const data = new FormData();
    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_or_register_data');

    data.append('reg_passw', reg_passw);
    data.append('reg_email', reg_email);

    data.append('idUser', idUser);
    data.append('id', id);
    data.append('name', name);
    data.append('pass', pass);
    data.append('email', email);
    data.append('file', file);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('access_dash', response.data.access_dash);
        if(idUser == id){
            const newData = response.data.access_dash.find(item => item.id == idUser);
            cookies.set(typeCookie, 'dashboard', '/', cookiesRegister);
            cookies.set(typeCookieEmail, newData.email, '/', cookiesRegister);

            if(pass != ''){
                cookies.set(typeCookiePassw, newData.pass, '/', cookiesRegister);
            }
        }

    }).catch((error)=>{
        CallbackError();
    })
}

// Home
export function Reg_Home(CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', 'home');
    data.append('edit_data', 'edit_or_register_data');

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('home', response.data);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Panels
export function Reg_Panels(idPage, idData, type, file, format, premium, available, busyUntil, state, city, neighborhood, coverage, address, latitude, longitude, streetView, flowGenerators, codeVideo, size, impactsMonth, publicType, similar_1, similar_2, similar_3, newHood, newCvrg, show_branch, show_newBranch, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', 'panels');
    data.append('edit_data', 'edit_or_register_data');

    data.append('id_page', idPage);
    data.append('idData', idData);
    data.append('type', type);
    data.append('file', file);
    data.append('format', format);
    data.append('premium', premium);
    data.append('available', available);
    data.append('busy_until', busyUntil);
    data.append('state', state);
    data.append('city', city);
    data.append('neighborhood', neighborhood);
    data.append('address', address);
    data.append('latitude', latitude);
    data.append('longitude', longitude);
    data.append('street_view', streetView);
    data.append('flow_generators', flowGenerators);
    data.append('code_video', codeVideo);
    data.append('size', size);
    data.append('impacts_month', impactsMonth);
    data.append('public', publicType);
    data.append('similar_1', similar_1);
    data.append('similar_2', similar_2);
    data.append('similar_3', similar_3);
    data.append('newHood', newHood);
    data.append('branch', show_branch);
    data.append('newBranch', show_newBranch);

    if(newCvrg){
        newCvrg.forEach(element => {
            data.append('newCvrg[]', element.value);
        });
    }

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        if(response.data.id_page){
            SetListPag('currentPageId', response.data.id_page);
        }
        SetListDataSingle('panels', response.data.panels);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// About
export function Reg_About(title, text, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', 'about');
    data.append('edit_data', 'edit_or_register_data');

    data.append('title', title);
    data.append('text', text);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('about', response.data);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Contact
export function Reg_Contact(subtitle_1, phone_1, whatsapp_1, address_1, email_1, subtitle_2, phone_2, whatsapp_2, address_2, email_2, subtitle_3, phone_3, whatsapp_3, address_3, email_3, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', 'contact');
    data.append('edit_data', 'edit_or_register_data');

    data.append('subtitle_1', subtitle_1);
    data.append('phone_1', phone_1);
    data.append('whatsapp_1', whatsapp_1);
    data.append('address_1', address_1);
    data.append('email_1', email_1);

    data.append('subtitle_2', subtitle_2);
    data.append('phone_2', phone_2);
    data.append('whatsapp_2', whatsapp_2);
    data.append('address_2', address_2);
    data.append('email_2', email_2);

    data.append('subtitle_3', subtitle_3);
    data.append('phone_3', phone_3);
    data.append('whatsapp_3', whatsapp_3);
    data.append('address_3', address_3);
    data.append('email_3', email_3);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('contact', response.data);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Modal
export function Reg_Modal(favorite, generateLink, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', 'modal');
    data.append('edit_data', 'edit_or_register_data');

    data.append('favorite', favorite);
    data.append('generateLink', generateLink);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('modal', response.data);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
