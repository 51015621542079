import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalData, SetModalState } from 'interface/InterfacePopUp';
import { GetUserData, RegisterUserData } from 'interface/InterfaceUsers';
import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";

import { SvgContact, SvgDashboardAbout, SvgDashboardAccess, SvgDashboardExit, SvgDashboardHome, SvgDashboardPanels, SvgText, SvgUser } from 'components/SvgFile';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePassw } from 'dataFixed';

import PopUP_Profile from 'components/PopUp/Profile';
import PopUp_ShowFile from 'components/PopUp/ShowFile';
import PopUP_Confirmation from 'components/PopUp/Confirmation';

import Panels from 'components/Page/Panels';
import Panels_Details from 'components/Page/Panels/Details';
import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';
import About from 'components/Page/About';
import Home from 'components/Page/Home';
import AccessDash from 'components/Page/AccessDash';
import Contact from 'components/Page/Contact';
import Modal from 'components/Page/Modal';

export default function Contents(){

    const cookies = new Cookies();
    const [ userId, setUserId ]           = useState(GetUserData('id'));
    const [ userFile, setUserFile ]       = useState(GetUserData('file'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterListPag('currentPage', setCurrentPage);
    }, []);

    function CurrentPage(){
        switch(currentPage) {
            case "access_dash":
                return <AccessDash />;

            case "home":
                return <Home />;

            case "panels":
                return <Panels />;
            case "panels_details":
                return <Panels_Details />;

            case "about":
                return <About />;

            case "contact":
                return <Contact />;

            case "modal":
                return <Modal />

            default:
                return(
                    <div className="no_data">
                        Selecione uma opção do menu na lateral para continuar...
                    </div>
                )
        }
    }

    function EditProfile(){
        SetModalData('Profile', { "type": "edit_user", "id": userId });
        SetModalState('Profile', true);
    }

    function CkickPage(value){
        SetListPag('currentPage', value);
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);

        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    return(
        <div className="Contents">
            <div className="list_menu">

                <div className="profile">
                    <div className="img_profile">
                        {
                            userFile !='' ?
                            <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                            <SvgUser color="#FFFFFF" className="show_img_profile" />
                        }
                    </div>
                    <div className="button_edit" onClick={ ()=>{ EditProfile() } }>Editar meu perfil</div>
                </div>

                <div className="opt_menu_dash">

                    <div className={ currentPage == "access_dash" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                        <div className="name_menu">
                            <div className="div_icons">
                                <SvgDashboardAccess color="#FFFFFF" className="icons_dash" />
                            </div>
                            <div>Acesso a dashboard</div>
                        </div>
                    </div>

                    {/*
                    <div className={ currentPage == "home" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("home"); } }>
                        <div className="name_menu">
                            <div className="div_icons">
                                <SvgDashboardHome color="#FFFFFF" className="icons_dash" />
                            </div>
                            <div>Home</div>
                        </div>
                    </div>
                    */}

                    <div className={ currentPage == "panels" || currentPage == "panels_details" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("panels"); } }>
                        <div className="name_menu">
                            <div className="div_icons">
                                <SvgDashboardPanels color="#FFFFFF" className="icons_dash" />
                            </div>
                            <div>Painéis</div>
                        </div>
                    </div>

                    <div className={ currentPage == "about" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("about"); } }>
                        <div className="name_menu">
                            <div className="div_icons">
                                <SvgDashboardAbout color="#FFFFFF" className="icons_dash" />
                            </div>
                            <div>Quem somos</div>
                        </div>
                    </div>

                    <div className={ currentPage == "contact" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("contact"); } }>
                        <div className="name_menu">
                            <div className="div_icons">
                                <SvgContact color="#FFFFFF" className="icons_dash" />
                            </div>
                            <div>Contato</div>
                        </div>
                    </div>

                    <div className={ currentPage == "modal" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("modal"); } }>
                        <div className="name_menu">
                            <div className="div_icons">
                                <SvgText color="#FFFFFF" className="icons_dash" />
                            </div>
                            <div>Texto Modal</div>
                        </div>
                    </div>

                    <div className="show_menu_dash" onClick={ ()=>{ Logout() } }>
                        <div className="name_menu">
                            <div className="div_icons">
                                <SvgDashboardExit color="#FFFFFF" className="icons_dash" />
                            </div>
                            <div>Sair</div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_Profile />
            <PopUp_ShowFile />
            <PopUP_Confirmation />
            <PopUP_ReturnResponse />
        </div>
    )
}
