import { useState, useEffect, useRef } from "react";

import './Modal.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

import { Reg_Modal } from "services/RegisterData";

import { SvgReturn } from "components/SvgFile";

export default function Modal(){

    const editText1 = useRef();
    const editText2 = useRef();
    const [ loading, setLoading ] = useState(false);

    const [ dataPage, setDataPage ]         = useState(GetDataPage('modal'));
    const [ favorite, setFavorite ]         = useState(dataPage.favorite);
    const [ generateLink, setGenerateLink ] = useState(dataPage.generateLink);

    useEffect(()=>{
        RegisterDataPage("modal", setDataPage);
    }, []);

    useEffect(()=>{
        setFavorite(dataPage.favorite);
        setGenerateLink(dataPage.generateLink);
    }, [dataPage]);

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Modal(favorite, generateLink, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
    }

    return(
        <div className="About">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="name_title_page">
                        Texto do modal
                    </div>
                    <div className="list_opt_alt_page">
                        <button className="new_block_text new_register">
                            Salvar
                        </button>
                    </div>
                </div>
                <div className="show_data_register">

                    <div className="div_show_text">
                        <div className="show_title">
                            <div className="title_div">Tutorial de favoritos</div>
                        </div>
                        <div className="show_textarea">
                            <JoditEditor ref={ editText1 } config={ config } value={ favorite ? favorite.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setFavorite(newContent) } />
                        </div>
                    </div>

                    <div className="div_show_text">
                        <div className="show_title">
                            <div className="title_div">Tutorial de gerar o link</div>
                        </div>
                        <div className="show_textarea">
                            <JoditEditor ref={ editText2 } config={ config } value={ generateLink ? generateLink.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setGenerateLink(newContent) } />
                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}
