// Connection type
// const pageServidor = 'http://localhost/project_dfl/amplamidia-dashboard/model_new/public';
const pageServidor  = '.';
export const connectionPage = pageServidor;

// Cookies type
// const cookiesServidor        = 'localhost';
const cookiesServidor         = 'dashboard.ampla-midia.com/';
export const cookiesRegister  = cookiesServidor;

// Cookies name
export const typeCookie       = 'ampla_portfolio_dash';
export const typeCookiePassw  = 'ampla_portfolio_passw';
export const typeCookieEmail  = 'ampla_portfolio_email';
export const typeCookiePage   = 'ampla_portfolio_page';
export const typeCookiePageId = 'ampla_portfolio_page_id';

// opt box editor
export const config = {
    zIndex: 0,
    readonly: false,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    tabIndex: -1,
    buttons: ['bold', 'italic', 'underline', 'image', 'link', 'align', 'paragraph', 'ul', 'ol', 'source'],
    link: {
        modeClassName: false,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        processPastedLink: false,
        processVideoLink: false,
        selectMultipleClassName: false,
        selectOptionsClassName: false,
    },
    imageDefaultWidth: 24,
    image: {
        dialogWidth: true,
        editAlign: false,
        editAlt: false,
        editBorderRadius: true,
        editClass: false,
        editId: false,
        editLink: true,
        editMargins: false,
        editSize: true,
        editSrc: true,
        editStyle: false,
        editTitle: false,
        openOnDblClick: true,
        selectImageAfterClose: true,
        showPreview: true,
        useImageEditor: true,
    },
    placeholder: "Digite o texto aqui...",
    toolbarAdaptive: false
}
