import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import { GetListPag, RegisterListPag, SetListPag } from "interface/InterfacePag";

import { typeCookie, typeCookieEmail, typeCookiePageId, typeCookiePassw } from "dataFixed";

import Login from "templates/Login";
import Contents from "templates/Contents";

import { Access } from "services/AccessLogin";

export default function App() {

    const cookies = new Cookies();
    const [ typePage, setTypePage ] = useState(GetListPag('page'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    useEffect(()=>{
        RegisterListPag('page', setTypePage);
        RegisterListPag('currentPage', setCurrentPage);

        if(cookies.get(typeCookie)){
            let email = cookies.get(typeCookieEmail);
            let passw = cookies.get(typeCookiePassw);
            Access('return', email, passw, CallbackSuccess, CallbackError);
        }

        if(cookies.get(typeCookiePageId)){
            let idPage = cookies.get(typeCookiePageId);
            if(idPage == 'undefined'){
                if(currentPage == 'project'){
                    SetListPag('currentPage', 'portfolio');
                    SetListPag('currentPageId', 'remuve');
                }

                if(currentPage == 'show_details'){
                    SetListPag('currentPage', 'presentation');
                    SetListPag('currentPageId', 'remuve');
                }
            }else {
                SetListPag('currentPageId', idPage);
            }
        }
    }, []);

    function CallbackSuccess(){
    }

    function CallbackError(){
    }

    // checked page
    function CheckedPage(){
        switch(typePage){
            case "dashboard":
                return(
                    <Contents />
                );

            default:
                return(
                    <Login />
                );
        }
    }

    return CheckedPage();
}
