import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/InterfacePopUp";
import { Reg_Delete } from "services/RegisterData";

export default function PopUP_Confirmation(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    useEffect(()=>{
        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    function TypeAlt(){
        Reg_Delete(modalData.id, modalData.origin, modalData.type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() })
        SetModalState('Confirmation', false);
    }

    function CallbackSuccess(){
    }

    function CallbackError(){
    }

    function ClosePopUp(){
        SetModalState('Confirmation', false);
    }

    function TypeRemoval(){
        switch(modalData.type){
            case "update_json":
                return "Atualizar plataforma";
            default:
                return (
                    <div>
                        Deletar:
                        <div>{ modalData.name }</div>
                    </div>
                );
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all confirmation">
                        <div className="div_data">
                            <div className="title confirmation_title">
                                { TypeRemoval() }
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content confirmation_content">
                                <div onClick={ ()=>{ TypeAlt(); } }>
                                    <div className="opt_select yes_update">Sim</div>
                                </div>
                                <div onClick={ ()=>{ ClosePopUp(); } }>
                                    <div className="opt_select not_update">Não</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
