import { useState, useEffect } from "react";

import './AccessDash.css';

import Cookies from "universal-cookie";

import { SvgDelete, SvgEdit, SvgUser, SvgUserDisabled } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/InterfacePopUp";
import { GetDataPage, RegisterDataPage } from "interface/InterfaceData";

import { typeCookie, typeCookieEmail } from "dataFixed";

export default function AccessDash(){

    const cookies = new Cookies();

    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('access_dash'));
    const [ emailUser, setEmailUser ]       = useState('');

    useEffect(()=>{
        RegisterDataPage('access_dash', setShowDataPage);

        if(cookies.get(typeCookie)){
            setEmailUser(cookies.get(typeCookieEmail));
        }

    }, []);

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "access_dash", "type" : "delete_data", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function EditData(id){
        SetModalData('Profile', { "type": "edit_profile", "id": id });
        SetModalState('Profile', true);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="AccessDash">
            <div className="new_block">
                <div />
                <div className="new_block_text" onClick={ ()=>{ EditData(0) } }>Cadastrar usuário</div>
            </div>
            <table width="100%" cellPadding="2" cellSpacing="6">
                <tbody>
                    <tr>
                        <th width="20" align="center">#</th>
                        <th>Nome</th>
                        <th width="200">E-mail</th>
                        <th width="40">Foto</th>
                        <th width="30" align="right">#</th>
                    </tr>
                </tbody>
                <tbody>
                    {
                        showDataPage.map((key, index)=>{

                            return(
                                <tr key={ index }>
                                    <td align="center">{ index + 1 }</td>
                                    <td>{ key.name }</td>
                                    <td>{ key.email }</td>
                                    <td align="center">
                                        <div>
                                            {
                                                key.file !='' ?
                                                <img alt="profile user" src={ "./assets/login/" + key.file } className="icon_user" onClick={ ()=>{ OpenFile('showImg', "login/" + key.file) } } /> :
                                                <SvgUser color="#324d6b" className="icon_user" />
                                            }
                                        </div>
                                    </td>
                                    <td align="right">
                                        <div className="div_opt_alt">
                                            {
                                                emailUser == key.email ? <div /> :
                                                <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                            }
                                            <div onClick={ ()=>{ EditData(key.id) } }>
                                                <SvgEdit color="#324d6b" className="icons"/>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
