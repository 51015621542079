
let DataPage = {
    "acces_dash" : [],
    "about"      : {},
    "home"       : [],
    "panels"     : [],
    "contact"    : {},
    "modal"      : {},
    "state"      : []
};

let NotifyDataPage = {
    "acces_dash" : [],
    "about"      : [],
    "home"       : [],
    "panels"     : [],
    "contact"    : [],
    "modal"      : [],
    "state"      : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["acces_dash"].forEach(element => {
        element(value['acces_dash']);
    });
    NotifyDataPage["about"].forEach(element => {
        element(value['about']);
    });
    NotifyDataPage["home"].forEach(element => {
        element(value['home']);
    });
    NotifyDataPage["panels"].forEach(element => {
        element(value['panels']);
    });
    NotifyDataPage["contact"].forEach(element => {
        element(value['contact']);
    });
    NotifyDataPage["modal"].forEach(element => {
        element(value['modal']);
    });
    NotifyDataPage["state"].forEach(element => {
        element(value['state']);
    });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
